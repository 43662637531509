(function(wnd){
	function notificationField(params){
		if( params )
			this.setParams( params );

		this.init();
	}

	notificationField.prototype = {
		text:undefined,
		title:undefined,
		link:undefined,
		notifs:[],
		during:45000,
		wait: 5000,
		opened: false,
		init: function(){
			this.createDOM();
			this.addDOMEvents();
		},
		createDOM: function(){
			this.content = this.createContent();	
			$('body').append(this.content);
		},
		addDOMEvents: function(){
			var _self = this;

			setTimeout(function(){
				_self.content.fadeIn(300, function(){
					_self.setOpened( true );
					setTimeout(function(){
						if( _self.isOpened() ){
							_self.setOpened( false );
							_self.content.fadeOut(300);
						}
					}, _self.getDuring() );
				});

			}, _self.getWait() );

			this.content.find('.close-btn').click(function(){	
				var tmp = $(this);
				$.ajax({
					type: "POST",
					url: "ajax/close_notyf/",
					dataType: "json",
					data: {id : tmp.parent().find('[data-purpose="notif-id"]').val()},
					success: function(rData){
						if(rData.success == 1){
							_self.setOpened( false );
							tmp.parent().fadeOut(300);
						}
					}
				});
				return false;
			});
		},
		createContainer: function(){
			return $('<div>', {'class': 'notification-field-outer'});
		},
		createContent: function(){
			var _self = this;

			var container = _self.createContainer();

			_self.notifs.forEach((notif) => {		
				var content = $('<a>', {
					'href': notif.link ? notif.link : 'javascript:void(0)',
					'class':'notification-field-item',
					'target':'blank'
				});
				content.append('<span class="close-btn"></span>');
				if(notif.title)
					content.append('<span class="title">'+notif.title+'</span>');
				if(notif.text)
					content.append('<span class="text">'+notif.text+'</span>');	
					
				var hidden_id = $('<input/>', {
					'name': 'hidden_id',					
					'id': 'hidden-id-'+notif.id,                
					'data-purpose': 'notif-id',					
					'type': 'hidden',
					'value': notif.id
				});

				content.append(hidden_id);	

				container.append(content);
			});			
			
			return container;
		},
		setParams: function( params ){
			if(params.title) this.setTitle( params.title );
			if(params.text) this.setText( params.text );
			if(params.link) this.setLink( params.link );
			if(params.notifs) this.setNotifs( params.notifs );
		},
		setNotifs: function( notifs ){
			this.notifs = notifs;
		},
		setTitle: function( title ){
			this.title = title;
		},
		setText: function( text ){
			this.text = text;
		},
		setLink: function( link ){
			this.link = link;
		},
		setWait: function( wait ){
			this.wait = wait;
		},
		setDuring: function( during ){
			this.during = during;
		},
		setOpened: function( opened ){
			this.opened = opened;
		},
		getTitle: function(){
			return this.title;
		},
		getText: function(){
			return this.text;
		},
		getLink: function(){
			return this.link != undefined && this.link.trim() != '' ? this.link : 'javascript:void(0)'; 
		},
		getWait: function(){
			return this.wait;
		},
		getDuring: function(){
			return this.during;
		},
		isOpened: function(){
			return this.opened;
		}
	};

	wnd.notificationField = notificationField;

})(window);