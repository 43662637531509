var currentCollectedDate = null;
var validateZip = false;

$(document).ready(function () {
    $('[data-purpose="type-radio"]').on('change', function() {
        var type = $(this).val();

        $('.landfill-form-container .additional-content').hide();
        $('.landfill-form-container .additional-content').each(function() {
            var expectValue = $(this).data('expect-value').split('|');
            if (expectValue.indexOf(type) >= 0) {
                $(this).show();
            }
        });

        if (currentCollectedDate) {
            loadTimeOptions(currentCollectedDate);
        }
    });

    $('[data-purpose="waste-type-checkbox"]').on('change', function() {
        var type = $(this).data('id');

        var infoContent = $('[data-purpose="waste-info"][data-id="'+type+'"]');
        if (infoContent.length) {
            infoContent.slideToggle();
        }
    });

    $('#zip').on('blur', function() {
        var zip = $(this).val();
        var errorContent = $('[data-purpose="error-content"][data-field="zip"]');
        if (typeof zipCities[zip] !== 'undefined') {
            $('#city').val(zipCities[zip]);
            errorContent.removeClass('active');
            errorContent.html('');
        } else if (validateZip) {
            $('#city').val('');
            errorContent.addClass('active');
            errorContent.html(landfillSettings.texts.invalid_zip);
        }
    });

    if (typeof landfillSettings === 'object') {
        $('#email').on('change', function() {
            var email = $(this).val();

            if ('ugyfelszolgalat@aksd.hu' === email.trim()) {
                $('#landfillDate').pickadate('picker').set('max', false);
            } else {
                $('#landfillDate').pickadate('picker').set('max', landfillSettings.max);
            }
        });
        
        
        $('#landfillDate').pickadate({
            firstDay: 1,
            hiddenName: true,
            formatSubmit: 'yyyy-mm-dd',
            format: 'yyyy. mmmm dd.',
            editable: false,
            today: '',
            clear: '',
            close: '',
            min: landfillSettings.min,
            max: landfillSettings.max,
            disable: landfillSettings.closedDays,
            onSet: function(context) {
                if (typeof context.select !== 'undefined') {
                    var date = new Date(context.select);
                    var month = date.getMonth();
                    currentCollectedDate = date.getFullYear() + '-' + (month+1) + '-' + date.getDate();
                    loadTimeOptions(currentCollectedDate);
                }
            }
        });
    }

    $('[data-purpose="navigation-link"]').each(function(){
		setNavigationUrl($(this));
	});

    $('[data-purpose="current-day"]').on('click', function() {
        $(this).toggleClass('opened');
        $('[data-purpose="other-days"]').slideToggle();
    });

    $('#landfillForm').on('submit', function(evt) {
        evt.preventDefault();
        sendLandfillForm($(this));
    });

    $('[data-purpose="landfill-form-btn"]').on('click', function() {
        $('#landfillForm').submit();
    });

    $('#cancelBookingForm').on('submit', function(evt) {
        evt.preventDefault();
        cancelLandfillBooking($(this));
    })
    
    $('[data-purpose="cancel-landfill-btn"]').on('click', function() {
        $('#cancelBookingForm').submit();
    });
});

var sendedForm = false;
function sendLandfillForm(form)
{
    if (!sendedForm) {
        sendedForm = true;

        form.find('#city').removeAttr('disabled');
        $.ajax({
            url: ajax_controller+'send_landfill_form',
            dataType:'json',
            type: 'post',
            data: form.serialize(),
            beforeSend: function() {
                form.find('[data-purpose="error-content"]').removeClass('active');
                form.find('.invalid').removeClass('invalid');
            },
            success: function(response) {
                var feedbackContainer = form.find('[data-purpose="feedback"]');
                form.find('#city').attr('disabled', true);

                if (response.success) {
                    window.location.href = response.redirect_url;
                } else {
                    var fields = Object.keys(response.errors);
                    if (fields.length > 0) {
                        $.each(response.errors, function(field, msg){
                            var input = form.find('[name="'+field+'"]');
                            
                            if (input.length) {
                                input.addClass('invalid');
                                var parent = input.parent();
                                if (input.is('select')) {
                                    parent = input.parent().parent();
                                }

                                if (parent.hasClass('input-cont')) {
                                    parent.addClass('invalid');
                                }
                            }

                            if (msg.trim()) {
                                var errorContainer = $('[data-purpose="error-content"][data-field="'+field+'"]');
                                
                                if (errorContainer.length) {
                                    errorContainer.html(msg);
                                    errorContainer.addClass('active');
                                }
                            }
                        });
                    }
                }

                if (feedbackContainer && response.message) {
                    showMessage(response.message, response.success ? 'success' : 'error', feedbackContainer);
                }
            },
            complete: function() {
                setTimeout(function(){
                    sendedForm = false;
                }, 400);
            }
        });
    }
}

function cancelLandfillBooking(form)
{
    if (!sendedForm) {
        sendedForm = true;

        $.ajax({
            url:ajax_controller+'cancel_landfill_booking',
            dataType:'json',
            type:'post',
            data:form.serialize(),
            success: function(response) {
                var feedbackContainer = form.find('[data-purpose="feedback"]');
                if (feedbackContainer && response.message) {
                    showMessage(response.message, response.success ? 'success' : 'error', feedbackContainer);
                }
            },
            complete: function() {
                setTimeout(function(){
                    sendedForm = false;
                }, 400);
            }
        });
    }
}

function setNavigationUrl(item) {
	var address = item.data('address');

	if (address) {
		var protocol = 'google';
		var urlSuffix = '/?daddr='+encodeURIComponent(address);

		switch(protocol) {
			default:
				nav_url = 'https://maps.google.com/maps';
				break;
		}
		item.attr('target', '_blank');
		item.attr('href', nav_url+urlSuffix);
	}
}

function loadTimeOptions(date) {
    var timeslotsContainer = $('[data-purpose="timeslot-container"]');
    var timeslotsContent = $('[data-purpose="timeslots"]');
    timeslotsContent.html('');
    
    $.ajax({
        url:ajax_controller+'get_landfill_timeslots',
        dataType:'json',
        type:'post',
        data: {
            date: date,
            type: $('[name="type"]:checked').val()
        },
        beforeSend: function(){
            $('[data-purpose="error-content"][data-field="timeslot"]').removeClass('active');
            $('[data-purpose="error-content"][data-field="timeslot"]').html('');
        },
        success: function(response) {
            if (!timeslotsContainer.is(':visible')) {
                timeslotsContainer.show();
                timeslotsContainer.addClass('active');
            }
            if (response.timeslots.length > 0) {
                for (var i = 0; i < response.timeslots.length; i++) {
                    var timeslot = response.timeslots[i];
                    var item = $('<div>', {
                        class: 'item'
                    });

                    item.append('<input name="timeslot" type="radio" value="'+timeslot+'" id="timeslot-'+i+'" class="timeslot-radio" />');
                    item.append('<label for="timeslot-'+i+'">'+timeslot+'</label>');
                    
                    timeslotsContent.append(item);
                }
            } else if (response.message) {
                $('[data-purpose="error-content"][data-field="timeslot"]').addClass('active');
                $('[data-purpose="error-content"][data-field="timeslot"]').html(response.message);
            }
        }
    });
}