
let reloadTimeout = null;

$(document).ready(function() {
	$('[data-purpose="archive-button"]').on('click', function() {
		const item = $(this).parents('.document-item');
		item.toggleClass('opened');
		item.find('.archive-container').slideToggle();
	});

    $('[data-purpose="document-filter-chk"]').on('change', function() {
        if (reloadTimeout) {
			clearTimeout(reloadTimeout)
		}

		reloadTimeout = setTimeout(function() {
			reloadDocumentPage();
		}, 1000);
    });

	$('[data-purpose="mobile-filter-button"]').on('click', function() {
		$('[data-purpose="document-filter-content"]').toggleClass('opened');
		$('body').toggleClass('opened-documents-filter');
	});
});

function reloadDocumentPage()
{
	const types = [];
	let path = '';

	$('[data-purpose="document-filter-chk"]:checked').each(function() {
		types.push($(this).val());
	});

	if (types.length > 0) {
		path = '/' + types.join('+');
	}

	window.location = document_controller + path;
}